/* You can add global styles to this file, and also import other style files */

/*@import 'assets/ey-design-system-scss/ey-design-system.scss';*/
/*@import 'assets/ey-design-system/css/ey-design-system.min.css';*/
@import '../node_modules/angular-notifier/styles/core.css';
@import '../node_modules/angular-notifier/styles/themes/theme-material.css';
@import '../node_modules/angular-notifier/styles/types/type-error.css';
@import '../node_modules/angular-notifier/styles/types/type-info.css';
@import '../node_modules/angular-notifier/styles/types/type-success.css';
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.k-i-loading {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  font-size: 64px;
  background-color: rgba(255,255,255,.3);
  color: black;
  height: auto;
  z-index: 99999;
}

.mr-auto, .mx-auto {
  margin-right: auto!important;
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 16px !important;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
.btn--primary {
  border: 0.0625rem solid transparent;
  color: #2e2e38;
  background: #ffe600;
  box-shadow: none; }
  .btn--primary:hover, .btn--primary:focus, .btn--primary:not(:disabled):not(.disabled):active {
    background-color: #ffe600;
    color: #2e2e38;
    border: 0.0625rem solid #aaaab1;
    cursor: pointer;

  }


.bg-black {
  background-color: black;
}

.text-white{
    color: white;
}

/* Tokens */
/* Primary EY Color Palette */

$color-ey-interaction-overlays: #1a1a24;
$color-ey-confident-grey: #2e2e38;
$color-ey-grey-1: #747480;
$color-ey-grey-2: #c4c4cd;
$color-ey-grey-2a: rgba(196, 196, 205, 0.5);
$color-ey-grey-3: rgba(196, 196, 205, 0.35);
$color-ey-grey-4: #f6f6fa;
$color-ey-grey-5: rgba(246, 246, 250, 0.5);
$color-white: #ffffff;
$color-ey-yellow: #ffe600;

/* Secondary EY Color Palette */

/* maroon */

$color-maroon-01: #c981b2;
$color-maroon-02: #b14891;
$color-maroon-03: #922b73;
$color-maroon-04: #750e5c;
$color-maroon-05: #5a0a42;
$color-maroon-06: #42152d;
$color-maroon-07: #351c21;

/* red */

$color-red-01: #ff9a91;
$color-red-02: #ff736a;
$color-red-03: #f95d54;
$color-red-04: #ff4136;
$color-red-05: #e0362c;
$color-red-06: #b9251c;
$color-red-07: #7a130d;

/* orange */

$color-orange-01: #ffb46a;
$color-orange-02: #ff9831;
$color-orange-03: #ff810a;
$color-orange-04: #ff6d00;
$color-orange-05: #f76900;
$color-orange-06: #eb4f00;
$color-orange-07: #bc2f00;

/* green */

$color-green-01: #8ce8ad;
$color-green-02: #57e188;
$color-green-03: #34c768;
$color-green-04: #2db757;
$color-green-05: #189d3e;
$color-green-06: #168736;
$color-green-07: #13652a;

/* teal */

$color-teal-01: #93f0e6;
$color-teal-02: #60e6e1;
$color-teal-03: #42c9c2;
$color-teal-04: #27acaa;
$color-teal-05: #109090;
$color-teal-06: #0d7575;
$color-teal-07: #004f4f;

/* blue */

$color-blue-01: #87d3f2;
$color-blue-02: #4ebeeb;
$color-blue-03: #35a4e8;
$color-blue-04: #188ce5;
$color-blue-05: #1777cf;
$color-blue-06: #155cb4;
$color-blue-07: #082c65;
$color-blue-08: #007bff;

/* purple */

$color-purple-01: #9c82d4;
$color-purple-02: #724bc3;
$color-purple-03: #542ea5;
$color-purple-04: #3d108a;
$color-purple-05: #240d75;
$color-purple-06: #0a095a;
$color-purple-07: #15173e;

/* States EY Color Palette */

// $color-standard-text: #2e2e38; --> Has the same value that the token $color-ey-confident-grey.
$color-prompt-label-text: rgba(46, 46, 56, 0.8);
$color-inactive-icon-text: rgba(46, 46, 56, 0.5);
$color-error: #89251c;
$color-warning: #ff9831;
$color-success: #168736;
$color-ey-link: #155cb4;
$color-validation-error: rgb(255, 66, 54);

/* Colors from GTP UI Guide */

/* Primary Colors */

$color-black: #000000;
$color-yellow: #d5c101;

/* Primary Grays */

$color-ey-dark-gray: #333333;
$color-ey-gray-alt: #646464;
$color-ey-gray: #808080;
$color-ey-gray-tint-1: #999999;
$color-ey-gray-tint-2: #c0c0c0;
$color-ey-gray-tint-3: #f0f0f0;
$color-ey-gray-border-1: #ececec;
$color-ey-gray-border-2: #e8e8e8;
$color-ey-gray-text: #3f3f48;

/* Secondary Colors */

$color-ey-red: #f04c3e;
$color-ey-link-blue: #336699;
$color-ey-yellow-50: #fff27f;

/* Homepage Background color */
$color-bg-homepage: #1c1c27;

/* Tertiary Color */

$color-gray-alt-1: #4a4a4a;
$color-gray-alt-2: #d8d8d8;
$color-gray-alt-4: #f6f6f6;

/* Disabled Color */

$color-disabled: rgba(196, 196, 205, 0.35);

/* Shadow Color */

$color-shadow: rgba(130, 138, 145, 0);
$color-shadow-2: rgba(0, 0, 0, 0.15);

/* Border Color */

$color-border: #aaaab1;
$color-border-2: #e7e7ea;

/* Fonts */

$font-family: "EYFontRegular", "Noto Sans", Arial, sans-serif;
$font-family-fontAwesome: FontAwesome;
$font-family-alternate: Georgia;
$font-family-bold: "EYFontBold", "Noto Sans", Arial, sans-serif;
$font-family-light: "EYFontLight", "Noto Sans", Arial, sans-serif;
$font-family-notoSans: "Noto Sans", Arial, sans-serif;
$font-size: 16px;

/* Font Size */
$font-size-1: 0.5rem;
$font-size-2: 0.625rem;
$font-size-3: 0.75rem;
$font-size-4: 0.875rem;
$font-size-5: 1rem;
$font-size-6: 1.125rem;
$font-size-7: 1.25rem;
$font-size-8: 1.5rem;
$font-size-9: 2rem;
$font-size-10: 2.5rem;
$font-size-11: 3rem;

/* Breakpoints */
$break-point-smaller: 350px;
$break-point-small: 576px;
$break-point-medium: 768px;
$break-point-large: 992px;
$break-point-extra-large: 1200px;

/* Spacing for padding and margin */
$spacing-0: 0rem;
$spacing-01: 0.3125rem;
$spacing-02: 0.625rem;
$spacing-03: 0.9375rem;
$spacing-04: 1.25rem;
$spacing-05: 1.5625rem;
$spacing-06: 1.875rem;
$spacing-07: 2.1875rem;
$spacing-08: 2.5rem;

$spacing-collection: ( $spacing-0, $spacing-01, $spacing-02, $spacing-03, $spacing-04, $spacing-05, $spacing-06, $spacing-07, $spacing-08 );
$type-spacing-collection: ("m", "p", "h");

@for $s from 0 to length($spacing-collection) {
  @for $t from 0 to length($type-spacing-collection) {
    @if nth($type-spacing-collection, $t + 1) == m {
      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)} {
        margin: nth($spacing-collection, $s + 1);
      }

      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)}-t {
        margin-top: nth($spacing-collection, $s + 1);
      }

      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)}-r {
        margin-right: nth($spacing-collection, $s + 1);
      }

      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)}-b {
        margin-bottom: nth($spacing-collection, $s + 1);
      }

      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)}-l {
        margin-left: nth($spacing-collection, $s + 1);
      }
    }
    @else if nth($type-spacing-collection, $t + 1) == p {
      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)} {
        padding: nth($spacing-collection, $s + 1);
      }

      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)}-t {
        padding-top: nth($spacing-collection, $s + 1);
      }

      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)}-r {
        padding-right: nth($spacing-collection, $s + 1);
      }

      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)}-b {
        padding-bottom: nth($spacing-collection, $s + 1);
      }

      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)}-l {
        padding-left: nth($spacing-collection, $s + 1);
      }
    }
    @else if nth($type-spacing-collection, $t + 1) == h {
      .spacing-0#{$s}-#{nth($type-spacing-collection, $t + 1)} {
        height: nth($spacing-collection, $s + 1);
      }
    }
  }
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/MaterialIcons-Regular.woff2") format("woff"), url("./assets/fonts/MaterialIcons-Regular.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Noto Sans"), local("NotoSans"), url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr5TRA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "EYFontLight";
  src: local("EYFontLight");
  src: url("/assets/fonts/EYFontLight.woff2") format("woff2"), url("/assets/fonts/EYFontLight.woff") format("woff");
  // src: url("assets/fonts/EYFontLight.eot"), url(" /assets/fonts/EYFontLight.ttf") format("truetype"), url("/assets/fonts/EYFontLight.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/EYFontLight.ttf") format("truetype"), url("/fonts/EYFontLight.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "EYFontRegular";
  src: local("EYFontRegular");
  src: url("/assets/fonts/EYFontRegular.woff2") format("woff2"), url("/assets/fonts/EYFontRegular.woff") format("woff");
  // src:url("/assets/fonts/EYFontRegular.ttf") format("truetype"), url("/assets/fonts/EYFontRegular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/EYFontRegular.woff") format("woff"), url("/assets/fonts/EYFontRegular.woff2") format("woff2"), url("/assets/fonts/EYFontRegular.ttf") format("truetype"), url("/fonts/EYFontRegular.svg") format("svg");
}

@font-face {
  font-family: "EYFontBold";
  src: local("EYFontBold");
  src: url("/assets/fonts/EYFontBold.woff2") format("woff2"), url("/assets/fonts/EYFontBold.woff") format("woff");
  // src: url("/assets/fonts/EYFontBold.ttf") format("truetype"), url("/assets/fonts/EYFontBold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/EYFontBold.woff") format("woff"), url("/assets/fonts/EYFontBold.woff2") format("woff2"), url("/assets/fonts/EYFontBold.ttf") format("truetype"), url("/fonts/EYFontBold.svg") format("svg");
}
// mixins

@font-face {
  font-family: "EYInterstate-Regular";
  src: local("EYInterstate-Regular");
  src: url("/assets/fonts/EYInterstate-Regular.woff2") format("woff2"), url("/assets/fonts/EYInterstate-Regular.woff") format("woff");
  // src: url("/assets/fonts/EYFontBold.ttf") format("truetype"), url("/assets/fonts/EYFontBold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/EYFontBold.woff") format("woff"), url("/assets/fonts/EYFontBold.woff2") format("woff2"), url("/assets/fonts/EYFontBold.ttf") format("truetype"), url("/fonts/EYFontBold.svg") format("svg");
}

@mixin fonts-col__heading-1 {
  font-size: $font-size-11 !important;
  font-family: $font-family-light !important;
  line-height: 3.75rem !important;
}

@mixin fonts-col__heading-2 {
  font-size: $font-size-10 !important;
  font-family: $font-family-light !important;
  line-height: 3.125rem !important;
}

@mixin fonts-col__heading-3 {
  font-size: $font-size-9 !important;
  font-family: $font-family-light !important;
  line-height: 2.5rem !important;
}

@mixin fonts-col__heading-4($line-height: 1.875rem) {
  font-size: $font-size-8 !important;
  font-family: $font-family-light !important;
  line-height: $line-height !important;
}

@mixin fonts-col__heading-5($line-height: 1.75rem) {
  font-size: $font-size-7 !important;
  font-family: $font-family-light !important;
  line-height: $line-height !important;
}

@mixin fonts-col__heading-6($line-height: 1.5rem) {
  font-size: $font-size-6 !important;
  font-family: $font-family-light !important;
  line-height: $line-height !important;
}

@mixin fonts-col__heading-6--bold($line-height: 1.5rem) {
  font-size: $font-size-6 !important;
  font-family: $font-family-bold !important;
  line-height: $line-height !important;
}

@mixin fonts-col__main-nav-default-state($line-height: 1.5rem) {
  font-size: $font-size-5 !important;
  font-family: $font-family-light !important;
  line-height: $line-height !important;
}

@mixin fonts-col__main-nav-selected-state {
  font-size: $font-size-5 !important;
  font-family: $font-family-bold !important;
  line-height: 1.5rem !important;
}

@mixin fonts-col__sub-nav-default-state($line-height: 1.375rem) {
  font-size: $font-size-4 !important;
  font-family: $font-family-light !important;
  line-height: $line-height !important;
}

@mixin fonts-col__sub-nav-selected-state($line-height: 1.375rem) {
  font-size: $font-size-4 !important;
  font-family: $font-family-bold !important;
  line-height: $line-height !important;
}

@mixin fonts-col__body-copy($line-height: 1.375rem) {
  font-size: $font-size-4 !important;
  font-family: $font-family-light !important;
  line-height: $line-height !important;
}

@mixin fonts-col__alt-body-copy {
  font-size: $font-size-4 !important;
  font-family: $font-family-alternate !important;
  line-height: 1.375rem !important;
}

@mixin fonts-col__instructional-text($line-height: 1.25rem) {
  font-size: $font-size-3 !important;
  font-family: $font-family-light !important;
  line-height: $line-height !important;
}

@mixin fonts-col__table-data-headers($line-height: 1.25rem) {
  font-size: $font-size-3 !important;
  font-family: $font-family-bold !important;
  line-height: $line-height !important;
}

@mixin fonts-col__table-data($line-height: 1.25rem) {
  font-size: $font-size-3 !important;
  font-family: $font-family !important;
  line-height: $line-height !important;
}

@mixin fonts-col__form-field-default-state {
  font-size: $font-size-3 !important;
  font-family: $font-family !important;
  line-height: 1.25rem !important;
}

@mixin fonts-col__form-field-inputs {
  font-size: $font-size-3 !important;
  font-family: $font-family-light !important;
  line-height: 1.25rem !important;
}

@mixin fonts-col__form-field-input-labels {
  font-size: $font-size-3 !important;
  font-family: $font-family-light !important;
  line-height: 1.25rem !important;
}

@mixin fonts-col__labels {
  font-size: $font-size-2 !important;
  font-family: $font-family-bold !important;
  line-height: 1.25rem !important;
}

@mixin fonts-col__field-labels-on-focus($line-height: 1.125rem) {
  font-size: $font-size-2 !important;
  font-family: $font-family !important;
  line-height: $line-height !important;
}

@mixin fonts-col__field-labels-on-focus-light {
  font-size: $font-size-2 !important;
  font-family: $font-family-light !important;
  line-height: 1.125rem !important;
}

@mixin fonts-col__field-validation {
  font-size: $font-size-2 !important;
  color: $color-validation-error !important;
  font-family: $font-family-light !important;
  line-height: 1.125rem !important;
}



.colors-col__sub-nav-selected-state--last {
  margin: 0;
}


/* Initial Components*/
/* begin global button styles */
@mixin icons-buttons($icon, $font-size, $margin: "0 0.5rem 0 0") {
  margin: $margin;
  font-family: "Material Icons";
  font-size: $font-size;
  content: $icon;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "liga";
  font-feature-settings: "liga";
  line-height: inherit;
  vertical-align: bottom;
}
/* the btn class should be universal css styling for most buttons */

.btn {
  @include fonts-col__sub-nav-selected-state;
  color: $color-ey-confident-grey;
  transition: all 0.3s ease;
  border-radius: 0;
  border: none;
  height: 2.813rem;
  vertical-align: middle;
  padding-top: 0.0625rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 0;
  margin: 0.3125rem;
  /*   buttons should not be smaller then 6.875rem */
  min-width: 6.875rem;
  /* fighting bootstrp in IE11  */
  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem $color-shadow !important;
    outline: 0;
  }
  /* all buttons that hosts icons need more margin */
  i {
    margin: 0 0.5rem 0 0;
  }
  /* disabled buttons start ------------------------------*/
  &.disabled,
  &:disabled {
    background-color: $color-disabled;
    color: $color-ey-grey-1;
    opacity: 1;
    border: 0.0625rem solid $color-disabled;
    cursor: not-allowed;
  }

  &:disabled:hover,
  &:disabled:active {
    background-color: $color-disabled;
    color: $color-ey-grey-1;
    opacity: 1;
    border: 0.0625rem solid $color-disabled;
    cursor: not-allowed;
  }
  /* all buttons need to have a transparent border to prevent bouncing and support in IE11*/
  &.disabled {
    border: 0.0625rem solid transparent;
  }

  & .material-icons {
    font-size: $font-size;
    vertical-align: bottom;
    line-height: inherit;
  }
}

.model-label{
  font-size: 22px !important;
  font-weight:bold;
}
/* button primary start ------------------------- */

.btn--primary {
  border: 0.0625rem solid transparent;
  color: $color-ey-confident-grey;
  background: $color-ey-yellow;
  box-shadow: none;
  border-width: thin !important;
  border-color: #3a3a4a !important;
  font-weight: bold;

  &:hover,
  &:focus,
  &:disabled,
  &:not(:disabled):not(.disabled):active {
    background-color: $color-ey-yellow;
    color: $color-ey-confident-grey;
    border: 0.0625rem solid $color-border;
    cursor: pointer;
    border-color: white !important;
  }

  
}

/* button secondary starts here ------------------------ */

.btn--secondary {
  background: none;
  color: $color-ey-confident-grey;
  border: 0.0625rem solid $color-ey-confident-grey;
  -webkit-box-shadow: none;
  box-shadow: none;
  /* all buttons active should have a grey border on selection/active */
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background: $color-ey-grey-4;
    color: $color-ey-confident-grey;
    border: 0.0625rem solid $color-border;
  }
}

/* button tertiary starts here ------------------------ */
.btn--tertiary,
.btn--icon {
  background: none;
  color: $color-ey-confident-grey;
  border: 0.0625rem solid $color-border-2;
  -webkit-box-shadow: none;
  box-shadow: none;
  /* all buttons active should have a grey border on selection/active */
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background: $color-ey-grey-4;
    color: $color-ey-confident-grey;
    border: 0.0625rem solid $color-border;
  }
}
/* button previous & next starts here ----------------- */
@mixin btn--previous-next($type) {
  background: $color-white;
  border: 0.0625rem solid $color-ey-confident-grey;
  padding: 0;

  @if $type==right {
    min-width: 5.0625rem;
    padding-left: 0.875rem;

    &::after {
      @include icons-buttons("chevron_right", 1.5rem);
    }
  }
  @else if $type==left {
    min-width: 5.125rem;
    padding-right: 0.875rem;

    &::before {
      @include icons-buttons("chevron_left", 1.5rem, "0 0 0 0.5rem");
    }
  }

  &:hover {
    border: 0.0625rem solid $color-border;
    background: $color-ey-grey-4;
  }

  $parentClass: &;

  &#{$parentClass}-primary {
    border: none;
    background: $color-ey-yellow;
  }

  &:hover {
    border: 0.0625rem solid $color-border;
  }
}

.btn--next {
  @include btn--previous-next("right");
}

.btn--back {
  @include btn--previous-next("left");
}

.btn--final-step {
  background: $color-ey-yellow;
  border: none;
  min-width: 4.875rem;
  padding: 0;
}
/* button approved & deny starts here ----------------- */
@mixin btn--approved-deny($active-bgColor, $icon) {
  border: 0.0625rem solid $color-ey-confident-grey;
  min-width: 9.9375rem;

  &:before {
    @include icons-buttons($icon, 1.5rem);
  }

  &:hover {
    border: 0.0625rem solid$color-border;
    background: $color-ey-grey-4;
  }

  &:focus {
    border: none;
    background: $active-bgColor;
    color: $color-white;
  }

  &:disabled {
    background: $color-border-2;
  }
}

.btn--approved {
  @include btn--approved-deny($color-green-06, "check");
}

.btn--deny {
  @include btn--approved-deny($color-red-06, "block");
}
/* button progress starts here ------------------------ */
@mixin btn--progress {
  &.in-progress:after,
  &.finished:after {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    padding-top: inherit;
    color: $color-white;
    left: 0;
  }

  & .tz-bar {
    background-color: $color-ey-confident-grey;
    height: 0.1875rem;
    bottom: 0;
    left: 0;
    width: 0;
    position: absolute;
    z-index: 1;
    -webkit-transition: width 0.5s, height 0.5s;
    -moz-transition: width 0.5s, height 0.5s;
    transition: width 0.5s, height 0.5s;
  }

  & .tz-bar.background-horizontal {
    height: 100%;
  }

  & .tz-bar.background-vertical {
    height: 0;
    top: 0;
    width: 100%;
  }
}

.btn--progress {
  @include btn--progress;
  position: relative;
  $parentClass: &;

  &#{$parentClass}-primary {
    background: $color-ey-yellow;
  }

  &#{$parentClass}-secondary {
    background: $color-white;
    border: 0.0625rem solid $color-ey-confident-grey;
    @include btn--progress;
  }

  &#{$parentClass}-tertiary {
    position: relative;
    background: $color-white;
    @include btn--progress;
  }
}

/* button utilitiy starts here ------------------------- */
.btn--utility {
  border: 0.0625rem solid transparent;
  background: $color-ey-grey-2;
  color: $color-ey-confident-grey;

  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    border: 0.0625rem solid $color-border;
    cursor: pointer;
  }
}

/* start button delete css ---------------------------- */

.btn--delete {
  background: $color-red-05;
  color: $color-white;
  border: 0.0625rem solid transparent;

  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    border: 0.0625rem solid $color-border;
    color: $color-white;
    cursor: pointer;
  }
}

/* start button link css ---------------------------- */

.btn--link {
  border: none;
  background-color: transparent;
  color: $color-ey-confident-grey;
  font-family: $font-family-bold;
  font-size: 0.875rem;
  padding: 0;
  vertical-align: middle;

  span {
    font-family: $font-family-bold !important;
  }

  i {
    font-size: 1rem;
  }

  &:hover {
    color: $color-ey-confident-grey;

    span {
      border-bottom: solid 0.0625rem;
      border-color: inherit;
      display: inline;
      padding-bottom: 0.1875rem;
    }
  }
}

.btn--link-blue {
  color: $color-ey-link;

  &:hover {
    color: $color-blue-06;
  }
}

.btn--icon {
  width: 3.125rem;
  height: 3.25rem;
  min-width: 3.125rem;
  min-height: 3.25rem;
  padding: 0;

  & i {
    margin: 0;
  }
}

$modal-width: 800px;
$animation: opacity 0.2s linear, right 0.2s ease-out;

.modal {
  display: none;
  z-index: 99999999;
  // cross component style
  &__header {
    border-bottom: none;
    padding: 0;
    margin: 0 0 1.5rem 0;
  }

  &__icon {
    height: 1.3125rem;
    width: 1.3125rem;
    color: $color-red-05;
    line-height: 1.125rem;
    margin: 0 0.5rem 0 0;
    vertical-align: middle;
  }

  &__title {
    color: $color-ey-confident-grey;
    @include fonts-col__heading-4(1.8125rem);
    margin: 0;
  }

  &__close {
    margin: 0 !important;
    padding: 0 !important;
    color: $color-ey-grey-2;
    width: 0.9375rem;
    height: 0.9375rem;
    position: relative;

    span {
      position: absolute;
      top: 0;
      left: 0;
      line-height: 0.9375rem;
      padding: 0;
      margin: 0;
      width: 0.9375rem;

      &::before {
        display: inline-block;
        width: 0.9375rem;
        height: 0.9375rem;
        vertical-align: middle;
        text-align: center;
        padding: 0;
        margin: 0;
        font-family: "Material Icons";
        line-height: 1rem;
        font-size: 0.9375rem;
        content: "close";
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: "liga";
      }
    }
  }

  &__body {
    padding: 0;

    p {
      @include fonts-col__sub-nav-default-state;
      text-align: left;
      padding: 0 !important;
      margin: 0 0 $spacing-08 0 !important;
    }
  }

  &__footer {
    padding: 2rem 0 0 0;

    &.modal__footer--center {
      justify-content: center;
    }

    & .btn {
      height: 45px;
      min-width: 153px;
      margin: 0;
    }
  }

  &__btn {
    margin-right: 1rem !important;

    & .btn {
      @include fonts-col__sub-nav-selected-state;
    }
  }

  &-content {
    padding: 2rem;
    border-radius: 0;
  }
}

.modal-open {
  overflow: inherit;
}

.modal-side {
  display: none;

  &.modal-side--right {
    .modal-dialog {
      position: fixed;
      margin: auto;
      max-width: $modal-width;
      width: 100%;
      height: 100%;
      -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
      -o-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
      z-index: 9999;
    }

    .modal-content {
      height: 100%;
      overflow-y: auto;
      border-radius: 0;
      z-index: 0;
      padding: 0;
    }

    .modal-body {
      padding: 1.875rem 2rem 0 2rem;
      font-family: EYFontRegular;
    }

    .modal-header {
      background-color: black;
      font-family: EYFontBold;
      font-size: 16px;
      color: white;
      //padding: 0px 0px 0px 0px !important;
    }

    .close {
      color: white;
    }

      // Right side
      &.modal-side--fade {
        .modal-dialog {
          right: -$modal-width;
          -webkit-transition: $animation;
          -moz-transition: $animation;
          -o-transition: $animation;
          transition: $animation;
        }
      }

      &.modal-side--fade.show {
        .modal-dialog {
          right: 0px;
        }
      }
    }

    &__header {
      border-bottom: 0.0625rem solid rgba($color-ey-grey-2, 0.35);
      background-color: rgba($color-ey-grey-4, 0.6);
      padding: 1.25rem 1.875rem;
      min-height: 4.25rem;
      position: relative;
    }

    &__title {
      @include fonts-col__heading-4(1.5rem);
      color: $color-ey-confident-grey;
      margin: 0;
    }

    &__close {
      color: $color-ey-confident-grey;
      font-size: 1.5625rem;
      font-weight: lighter;
      line-height: 0.75rem;
      margin: 0 !important;
      opacity: 1;
      padding: 0 !important;
      position: absolute;
      top: calc(50% - 7.5px);
      right: 24px;

      span {
        width: 0.9375rem;
        display: inline-block;
        height: 0.9375rem;
        vertical-align: middle;
      }
    }

    &__body {
      text-align: left;

      &-head {
        @include fonts-col__heading-6;
        color: $color-ey-confident-grey;
        padding: 0;
      }

      &-content {
        @include fonts-col__sub-nav-default-state;
        color: $color-ey-confident-grey;
        padding: 0;
      }
    }

    &__btn {
      margin-right: 1rem !important;
    }

    &__footer {
      $max-width: 470px;
      background-color: $color-white;
      border: none;
      bottom: 0;
      position: relative;
      width: 100%;

      @media (max-width: $max-width) {
        text-align: right;
      }

      .modal-side__back-btn {
        font-size: $font-size-4;
        color: $color-ey-link-blue;
        border: none;
        background-color: transparent;
        text-align: left;
        position: absolute;
        top: 70px;
        left: 32px;

        @media (max-width: $max-width) {
          display: inline;
          position: static;
          margin: 0.625rem 2rem 0 0;
        }

        &::before {
          display: inline-block;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          vertical-align: middle;
          text-align: center;
          padding: 0;
          margin: 0 8px 0 0;
          font-family: "Material Icons";
          line-height: 1rem;
          font-size: $font-size-3;
          content: "keyboard_arrow_left";
          color: $color-white;
          background-color: $color-ey-link-blue;
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizeLegibility;
          /* Support for Firefox. */
          -moz-osx-font-smoothing: grayscale;
          /* Support for IE. */
          font-feature-settings: "liga";
        }
      }

      hr {
        width: 95%;
        margin: 2rem auto 0 auto;
      }

      .modal-footer {
        border: none;
        padding: 2rem;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        button {
          margin: 0;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .modal-lg-prev {
      max-width: 98%;
    }
  }


  *.eyfontlight {
    font-family: $font-family-light !important;
  }

  *.eyfontbold {
    font-family: $font-family-bold !important;
  }

  *.eyfontregular {
    font-family: $font-family !important;
  }

  html, body {
    width: 100%;
    height: 100%;
    font-size: 14px;
    background-color: #F6F5FA;
    font-family: eyfontlight !important;
    //overflow-x: auto;
  }

  body {
    margin: 0;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
  }
  /*html {
  font-size: $font-size !important;
}*/
  .footer {
    &__group {
      font-family: $font-family;
      color: $color-white;
      font-size: 0.875rem;
      letter-spacing: 0;
      line-height: 1.5rem;
      text-align: center;
      background: $color-ey-confident-grey;
      width: 100%;
      vertical-align: middle;
      padding: 0 2rem 0 2rem;
    }

    &__image {
      margin-top: 2.429rem;
      margin-bottom: 0;
      width: 5.143rem;
      height: 5.143rem;
      vertical-align: middle;
      border-style: none;
    }

    &__parag {
      @include fonts-col__sub-nav-default-state;
      line-height: 1.313rem !important;
      padding: 1rem 1em 3.143rem 1em !important;
      margin-bottom: 0;

      & .material-icons {
        font-size: $font-size-5;
        vertical-align: sub;
      }
    }

    &--anchor,
    &--anchor:active,
    &--anchor:hover,
    &--anchor:visited {
      color: $color-ey-yellow;
      font-weight: 500;
      text-decoration: none;
      position: relative;
    }
  }

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 16px !important; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
  }

.k-grid {
  font-family: EYFontLight !important;
}

  .primary {
    background-color: #fee600;
    background-image: linear-gradient(to bottom, #fee600, #fee600);
    color: black;
    border-color: #fee600;
    cursor: pointer !important;
    border-radius: 0px;
  }

  .no-left-padding {
    padding-left: 0px;
  }

  .k-textbox::selection, .k-textarea::selection {
    background-color: black !important;
  }

  .k-textbox:focus {
    border-color: black !important;
  }

  .k-textbox-container::after, .k-floating-label-container::after {
    background-color: black !important;
    color: black !important;
  }

.k-grid-toolbar {
  padding: 0px 0px 0px 0px !important;
}

.k-dialog-titlebar, .k-state-selected {
  color: black !important;
  background-color: black !important;
}

.k-window-titlebar {
  padding: 20px 20px 20px 20px !important;
  font-family: EYFontBold;
  border-bottom: 1px solid #DCDCDC !important;
}

.k-pager-numbers .k-link.k-state-selected {
  background-color: #fee600 !important;
  z-index: unset !important;
}
  .k-window-titlebar + .k-window-content {
    padding-top: 24px !important;
    border-bottom: 1px solid #DCDCDC !important;
    margin-top: unset !important;
  }

  .k-window-content, .k-prompt-container {
    padding: 35px 35px 35px 35px !important;
  }

  .k-pager-numbers .k-link {
    border-radius: unset !important;
  }

.notifier__notification--material {
  z-index: 999999999999;
}
.custom-input{
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 100% !important;
}
.k-state-disabled .k-select{
  background-image: linear-gradient(#d3d3d3, #d3d3d3) !important;
  background-color: #D3D3D3 !important; 
  border-right-style: solid; 
  cursor: default !important;
}

.k-state-disabled .k-input{
  background-image: linear-gradient(#d3d3d3, #d3d3d3) !important;
  background-color: #D3D3D3 !important; 
  border-right-style: solid; 
  cursor: default !important;
}

.k-dropdown .k-dropdown-wrap, .k-dropdowntree .k-dropdown-wrap {
  border-width: 0.1em;
  border-radius: .25rem;
  border-color: #ced4da;
}

.k-dropdown-wrap>.k-input {
  background-color: white !important;
} 

/*.k-dialog-titlebar, .k-state-selected {
  color: #000!important;
  background-color: #fee600 !important;
}*/

.notifier__notification--material {
  z-index: 999999999999;
}

  .k-widget.k-state-invalid>.k-dateinput-wrap, .k-widget.k-state-invalid>.k-dropdown-wrap, .k-widget.k-state-invalid>.k-picker-wrap, .k-widget.k-state-invalid>.k-multiselect-wrap, .k-widget.k-state-invalid>.k-numeric-wrap, .ng-invalid.ng-touched>.k-dateinput-wrap, .ng-invalid.ng-touched>.k-dropdown-wrap, .ng-invalid.ng-touched>.k-picker-wrap, .ng-invalid.ng-touched>.k-multiselect-wrap, .ng-invalid.ng-touched>.k-numeric-wrap, .ng-invalid.ng-dirty>.k-dateinput-wrap, .ng-invalid.ng-dirty>.k-dropdown-wrap, .ng-invalid.ng-dirty>.k-picker-wrap, .ng-invalid.ng-dirty>.k-multiselect-wrap, .ng-invalid.ng-dirty>.k-numeric-wrap {
    color: black !important;
  }

.k-textbox.k-invalid, .k-textbox.k-state-invalid, .k-textbox.ng-invalid.ng-touched, .k-textbox.ng-invalid.ng-dirty, .k-textarea.k-invalid, .k-textarea.k-state-invalid, .k-textarea.ng-invalid.ng-touched, .k-textarea.ng-invalid.ng-dirty, .k-input.k-textbox.k-invalid, .k-input.k-textbox.k-state-invalid, .k-input.k-textbox.ng-invalid.ng-touched, .k-input.k-textbox.ng-invalid.ng-dirty {
  color: #656565 !important;
} 

/*.k-textbox, .k-textarea, .k-input.k-textbox {
  padding-left: 10px !important;
  padding-right: 10px !important;
  border-width: 0.1em !important;
  border-radius: .25rem;
  border-color: #ced4da;
}*/
.k-filtercell .k-filtercell-operator {
  display: none !important;
}

.k-filtercell .k-filtercell-wrapper>.k-textbox{ 
  content:'replacement string'; 
  display:  block; 
  text-indent:0 ;  
}

.k-state-disabled, .k-widget[disabled] {
  pointer-events: auto !important;
}

.k-i-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 64px;
  background-color: rgba(255,255,255,.3);
  color: #ffd200;
  height: auto;
  z-index: 99999;
}

.k-dropdown .k-dropdown-wrap .k-select {
  background-color: white;
}

.k-pager-numbers .k-link {
  color: black;
}

.k-textbox::selection, .k-input::selection, .k-textarea::selection {
  background-color: #668aff;
  color: white;
}

.k-widget ::selection, .k-block ::selection, .k-panel ::selection {
  background-color: #668aff;
  color: white;
}

.k-multiselect .k-multiselect-wrap, .k-dropdowntree .k-multiselect-wrap {
  min-width: 280px !important;
  cursor: pointer !important;
}

.k-multiselect:after {
  content: "\25BC";
  position: absolute;
  top: 30%;
  right: 12px; 
  font-size: 9px;  
} 

.k-clear-value {
  display: none !important;
}

.custom-input{
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 100% !important;
}

.k-state-disabled .k-input {
  width: 368px !important;
}

.dialogbutton{
  background-color: #fee600 !important;
}

.redindicator{
  color: red !important;
}
.k-textbox-container > .k-textbox, .k-textbox-container > .k-textarea, .k-textbox-container > .k-widget, .k-floating-label-container > .k-textbox, .k-floating-label-container > .k-textarea, .k-floating-label-container > .k-widget {
  border-color: black;
  border-top: 0px !important;
}
.k-textbox:hover{
  border-color: black !important;
}
.k-filter-row > td, .k-filter-row > th, .k-grid td, .k-grid-content-locked, .k-grid-footer, .k-grid-footer-locked, .k-grid-footer-wrap, .k-grid-header, .k-grid-header-locked, .k-grid-header-wrap, .k-grouping-header, .k-grouping-header .k-group-indicator, .k-header{
  border-color:rgba(0,0,0,.08);
}

.k-grid .k-grid-content-locked .k-alt td {
  background-color: unset !important;
}

.k-grid .k-grid-content-locked td {
  background-color: unset !important;
}

.k-grid .k-grid-header-locked th {
  background-color: white !important;
}

.k-grid-header .k-header:first-child {
  background-color: white !important;
}

.k-grid th {
  border-width: 0 0 1px 0px !important;
}

.word-wrap {
  max-width: 15ch !important;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.eyprim-btn {
  color: #333333 !important;
  background-color: #fee600 !important;
  border: 0rem;
  border-radius: 0rem;
  font-size: 14px;
  padding: 12px 22px;
  line-height: 14px;
  border-color: black !important;
}

/*.k-dateinput .k-dateinput-wrap, .k-dateinput .k-picker-wrap, .k-datepicker .k-dateinput-wrap, .k-datepicker .k-picker-wrap, .k-datetimepicker .k-dateinput-wrap, .k-datetimepicker .k-picker-wrap, .k-timepicker .k-dateinput-wrap, .k-timepicker .k-picker-wrap {
  border-width: 1px 1px 1px !important;
  border-radius: .25rem;
}*/
.k-i-download::before {
  font-size: larger;
}

.k-calendar {
  font-size: 10px !important;
}

.k-dropdown .k-dropdown-wrap, .k-dropdowntree .k-dropdown-wrap {
  border-width: 1px 1px 1px !important;

}

.k-dropdown-wrap > .k-input {
  padding-left: 5px !important;
}


.k-step-indicator{
  width: 50px !important;
  height: 50px !important;
}


.k-step-list-horizontal~.k-progressbar{
  top:27px !important;
}

.k-stepper .k-step-indicator {
  border-color: rgba(0,0,0,.5) !important;
}

.k-step-list-horizontal .k-step-link{
  max-width: 15em !important
}

.k-stepper .k-step-label {
  max-width: 15em !important;
}
.k-stepper .k-step-label .k-step-text {
  max-width: 14.25em !important;
}

.k-panelbar  .k-panelbar-header  .k-link .k-icon{
color: white !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
// .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
//   position: absolute !important;
  
// }
.k-notification-group {
    z-index:9999 !important;
}

.k-floating-label-container.k-state-focused > .k-label {
  top: 0px !important;
  color: red !important;
}

.k-floating-label-container > .k-label, .k-floating-label-container.k-state-focused > .k-label {
  font-size: 1rem !important;
  color: black !important;
  padding-left: 12px !important;
  top: 25px !important;
}

.k-floating-label-container:focus-within > .k-label {
  padding-left: 12px !important;
  top: 25px !important;
}

.k-floating-label-container > .k-label {
  padding-left: 12px !important;
  top: 25px !important;
}

.k-floating-label-container.k-state-empty > .k-label {
  padding-left: 12px !important;
  padding-bottom: 0px !important;
  top: 35px !important;
}

.k-floating-label-container.k-state-empty:focus-within > .k-label {
  top: 25px !important;
}

/*.k-input:focus-within {
  padding-left: 10px !important;
  
}*/
input.k-input {
  padding: 19px 10px 3px 10px !important;
  background: #f6f6fa;
  border-radius: 0px !important;
}

.k-floating-label-container > .k-textbox {
  min-height: 45px;
}

.k-input-md .k-input-inner, .k-picker-md .k-input-inner {
  padding: 19px 10px 3px 10px !important;
  z-index:unset !important;
}

.k-input-solid, .k-picker-solid {
  min-height: 45px;
  padding-left: 0px !important;
}

.k-picker {
  background: #f6f6fa !important;
}

.k-grid tbody {
  background-color: #fff !important;
}

.k-grid tbody .even{
  background-color: #fafafc !important;
}

.k-grid-header .k-with-icon, .k-grid-header .k-filterable {
  color: black !important;
}

.k-filtercell .k-filtercell-wrapper > .k-textbox {
    border-top: none !important;
}
input.custom-size {
    border-radius:0;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: black !important;
  color: white;
  background-color: black !important;
}

.k-radio:checked,
.k-radio.k-checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3e%3ccircle cx='50%25' cy='50%25' r='4' fill='black'/%3e%3c/svg%3e") !important;
  border-color: black !important;
}
.buttonmargin {
  border: 0.0625rem solid transparent !important;
  border-color: #2e2e38 !important;
  
}

.k-chip-md {
  padding-top: 15px !important;
}

.navbar {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
